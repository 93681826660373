<template>
  <base-document
    class="insertion-order-document accounting-document"
    v-bind="$props"
    show-menu
  >
    <div v-if="order">
      <div class="document-logo">
        <img
          alt="Order Logo"
          :src="order.document_logo"
          class="document-logo-image max-w-full max-h-full"
        />
      </div>

      <div class="document-header">
        <div class="document-header-top flex">
          <div class="flex-grow text-left document-contacts">
            <div class="document-customer-billing-info">
              <h3 class="document-customer-company">
                {{ buyer.buyer_company }}
              </h3>
              <div class="document-customer-billing-address">
                <address-format :address="order.customerAddress" />
              </div>
              <div v-if="order.primaryContact" class="document-primary-contact">
                <h3>Contact:</h3>
                <contact-info :contact="order.primaryContact" />
              </div>
              <div v-if="order.billingContact" class="document-billing-contact">
                <h3>Billing:</h3>
                <contact-info :contact="order.billingContact" />

                <address-format
                  v-if="order.billingAddress"
                  :address="order.billingAddress"
                />
              </div>
            </div>
          </div>

          <div class="flex-grow text-right document-info">
            <div class="document-info-main">
              <h3>{{ title }}</h3>
              <div class="document-transaction-date document-label-value">
                <div class="document-label">Date Created:</div>
                <div class="document-value">
                  {{ order.created_at | date }}
                </div>
              </div>
              <div class="document-ref document-label-value">
                <div class="document-label">Campaign #:</div>
                <div class="document-value">{{ campaign.ref }}</div>
              </div>
              <div class="document-ref document-label-value">
                <div class="document-label">Order #:</div>
                <div class="document-value">{{ order.ref }}</div>
              </div>
              <div class="document-total-due document-label-value">
                <div class="document-value">
                  {{ campaign.name }}
                </div>
              </div>
              <div class="document-total-due document-label-value">
                <div class="document-label">Total:</div>
                <div class="document-value">
                  {{ totalPayoutWithoutCpm | currency }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="document-header-bottom">
          <table class="document-vendor-contact doc-table">
            <thead>
              <tr>
                <td class="vendor-organization">
                  {{ supplier.name }}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <template v-if="supplier.billingAddress">
                    <address-format :address="supplier.billingAddress" />
                  </template>

                  <div class="vendor-billing-contact">
                    <div>Attn: {{ supplier.primaryContact.name }}</div>
                    <template v-if="adShop">
                      <div>{{ adShop.phone | phone }}</div>
                      <div>{{ adShop.email }}</div>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="document-body">
        <div>
          <table class="document-line-items-table doc-table">
            <thead>
              <tr>
                <td class="column-description line-item-description">
                  Description
                </td>
                <td class="column-date line-item-date">Date</td>
                <td class="column-quantity line-item-quantity">Quantity</td>
                <td class="column-amount line-item-amount">Amount</td>
              </tr>
            </thead>
            <tbody>
              <insertion-order-document-line-item
                v-for="lineItem in sortedLineItems"
                :key="'order-line-item-' + lineItem.id"
                :line-item="lineItem"
                :order="order"
              />
            </tbody>
          </table>
        </div>

        <transition-group
          tag="div"
          name="fade"
          class="relative animate-position"
        >
          <div :key="'document-footer'" class="document-total-due-footer">
            <div class="document-total-label">Total:</div>
            <div class="document-total-value">
              {{ totalPayoutWithoutCpm | currency }}*
            </div>
          </div>

          <div
            v-if="order.notes"
            :key="'document-notes'"
            class="document-notes"
          >
            <h3 class="document-notes-header">Notes</h3>
            <div class="document-notes-body"></div>
          </div>
        </transition-group>
      </div>
    </div>
    <div v-if="isNational && !isThirdParty" class="mt-10">
      <div class="text-center">
        * Digital ad payments are determined by full impressions delivered. You
        can view your monthly payment amounts in the Invoices tab under Billing.
      </div>
      <div class="mt-10 text-center">
        Please note: A 20% commission has been applied to each ad on this
        invoice.
      </div>
    </div>
  </base-document>
</template>

<script>
import AddressFormat from '@/components/Core/AddressFormat';
import ContactInfo from '@/components/Core/ContactInfo';
import BaseDocument from '@/components/Core/Documents/BaseDocument';
import InsertionOrderDocumentLineItem from '@/components/Supplier/Billing/Document/InsertionOrderDocument/InsertionOrderDocumentLineItem';
import { CampaignType } from '@/constants';
import { urlParams } from '@/utils/helpers';
import moment from 'moment';

export default {
  components: {
    AddressFormat,
    BaseDocument,
    ContactInfo,
    InsertionOrderDocumentLineItem
  },
  props: {
    ...BaseDocument.props,
    editable: Boolean,
    order: {
      type: Object,
      default: null
    },
    supplier: {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    title() {
      return !!urlParams('show_unapproved_ads')
        ? 'Order Request'
        : 'Insertion Order';
    },
    sortedLineItems() {
      return [...this.order.lineItems].sort((a, b) => {
        return moment(a.ads[0].start_date).isBefore(b.ads[0].start_date)
          ? -1
          : 1;
      });
    },
    isNational() {
      return (
        this.campaign && this.order.campaign.type === CampaignType.NATIONAL
      );
    },
    isThirdParty() {
      return this.supplier.organization_type === 'Third-Party Vendor';
    },
    campaign() {
      return this.order && this.order.campaign;
    },
    buyer() {
      return this.order && this.order.buyer;
    },
    adShop() {
      return this.order && this.order.adShop;
    },
    totalPayoutWithoutCpm() {
      return this.order.lineItems.reduce(
        (acc, li) =>
          acc +
          ((li.ads[0]?.payout_type || li.ads[0]?.adGroup?.payout_type) === 'CPM'
            ? 0
            : +li.pay_supplier),
        0
      );
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

.accounting-document {
  position: relative;
  font-size: 14px;
  background: white;

  &.is-disabled > div {
    opacity: 0.5;
    cursor: not-allowed;
  }

  h3 {
    font-size: 16px;
    line-height: 20px;
  }

  table.doc-table /deep/ {
    border-collapse: collapse;

    & > thead,
    & > tbody {
      & > tr {
        & > td {
          border: 1px solid $color-gray;
          padding: 4px 10px;
        }
      }
    }

    & > thead {
      & > tr {
        & > td {
          background: $color-baby-blue;
          color: $color-blue;
        }
      }
    }

    .line-item-quantity {
      width: 10em;
      text-align: center;

      input {
        text-align: center;
      }
    }

    .line-item-amount {
      width: 10em;
      text-align: right;
    }
  }

  .document-corner-status {
    position: absolute;
    top: 1em;
    right: 2em;
  }

  .document-label-value {
    display: flex;
    flex: 0 1;
    justify-content: flex-end;

    .document-value {
      padding-left: 8px;
    }
  }

  .document-logo {
    height: 80px;

    .document-logo-box {
      height: 80px !important;
      width: 300px !important;
    }
  }

  .document-customer-company {
    font-weight: bold;
  }

  .document-primary-contact {
    margin-top: 18px;
  }

  .document-billing-contact {
    margin-top: 18px;
  }

  .document-vendor-contact {
    width: 300px;
  }

  .vendor-billing-contact {
    font-weight: bold;
    margin-top: 10px;
  }

  .document-header {
    margin-top: 18px;
  }

  .document-header-bottom {
    margin-top: 18px;
  }

  .document-body {
    margin-top: 40px;
  }

  .document-line-items-table {
    width: 100%;
  }

  .date-to {
    text-align: center;
  }

  .document-total-due-footer {
    font-size: 20px;
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
    display: flex;

    .document-total-label {
      font-weight: bold;
      margin-right: 10px;
    }
  }
}
</style>
