<template>
  <div id="page-print-insertion-order-document">
    <supplier-insertion-order-document-query
      :id="$route.params.id"
      :supplier-id="$route.params.supplier_id"
    >
      <template v-slot="{ isLoading, data }">
        <div>
          <insertion-order-document
            :order="data && data.supplier.order"
            :supplier="data && data.supplier"
            :loading="isLoading"
          />
        </div>
      </template>
    </supplier-insertion-order-document-query>
  </div>
</template>

<script>
import SupplierInsertionOrderDocumentQuery from '@/components/Queries/Supplier/SupplierInsertionOrderDocumentQuery';
import InsertionOrderDocument from '@/components/Supplier/Billing/Document/InsertionOrderDocument/InsertionOrderDocument';

export default {
  components: {
    InsertionOrderDocument,
    SupplierInsertionOrderDocumentQuery
  },
  data() {
    return {};
  }
};
</script>
