<template>
  <tr
    class="insertion-order-line-item-row"
    @mouseenter="$emit('hovering', true)"
    @mouseleave="$emit('hovering', false)"
  >
    <template v-if="isAd">
      <td class="line-item-details">
        <div>Ad: #{{ ad.ref }}</div>
        <div v-if="categoryName">{{ categoryName }}</div>
        <div>{{ productName }}</div>
        <div>{{ ad.details }}</div>
      </td>
      <td class="line-item-date text-center">
        {{ ad.start_date | date }}
        <div v-if="showEndDate">
          <div class="text-center">to</div>
          {{ ad.end_date | date }}
        </div>
      </td>
      <td class="line-item-quantity">
        {{ lineItem.quantity }} {{ lineItem.unit }}
      </td>
      <td class="line-item-amount relative">
        <template v-if="isPayoutCpm">
          Payment based on delivery (see footnote below)
        </template>
        <template v-else>
          {{ lineItem.pay_supplier | currency }}
        </template>
      </td>
    </template>
    <template v-else-if="isDiscount">
      <td class="line-item-details">
        <div>Discount Code: #{{ discount.discount.name }}</div>
        <div v-if="discount.discount.notes">{{ discount.discount.notes }}</div>
      </td>
      <td class="line-item-date text-center">
        {{ order.created_at }}
      </td>
      <td class="line-item-quantity">
        1 discount
      </td>
      <td class="line-item-amount relative">
        {{ discount.amount | currency }}
      </td>
    </template>
  </tr>
</template>

<script>
import { CampaignType, OrderLineItemType } from '@/constants';
import moment from 'moment';

export default {
  props: {
    lineItem: {
      type: Object,
      default: null
    },
    order: {
      type: Object,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {
    ad() {
      return this.lineItem && this.lineItem.ads && this.lineItem.ads[0];
    },
    discount() {
      return this.lineItem && this.lineItem.discount;
    },
    isAd() {
      return this.lineItem.type === OrderLineItemType.AD;
    },
    isDiscount() {
      return this.lineItem.type === OrderLineItemType.DISCOUNT;
    },
    isPayoutCpm() {
      return (this.ad?.payout_type || this.ad?.adGroup?.payout_type) === 'CPM';
    },
    isNational() {
      return this.order && this.order.campaign.type === CampaignType.NATIONAL;
    },
    showEndDate() {
      return (
        this.ad.end_date &&
        moment(this.ad.end_date).diff(this.ad.start_date, 'days') > 0
      );
    },
    nationalProduct() {
      return this.ad.nationalTagPath;
    },
    isSsp() {
      return this.ad.orderProduct.is_ssp_product;
    },
    categoryName() {
      if (this.ad) {
        if (this.isSsp || this.nationalProduct) {
          return '';
        } else {
          const op = this.ad.orderProduct;
          return (
            op.medium.name + ' ' + op.property.name + ' ' + op.collection.name
          );
        }
      } else {
        return '';
      }
    },
    productName() {
      if (this.ad) {
        const op = this.ad.orderProduct;

        if (this.isSsp) {
          return (
            op.product_variant.name +
            (this.ad.production_size ? ` | ${this.ad.production_size}` : '') +
            (this.ad.position ? ` | ${this.ad.position}` : '')
          );
        } else if (this.nationalProduct && op.is_super) {
          return this.nationalProduct.name;
        } else {
          if (op.product.name === op.product_variant.name) {
            return op.product.name;
          } else {
            return op.product.name + ' ' + op.product_variant.name;
          }
        }
      } else {
        return 'Line Item';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.line-item-details {
  & > div {
    max-width: 30em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
