<script>
import { getPayableInvoiceDocument } from '@/graphql/accounting/getPayableInvoiceDocument.graphql';
import { QueryHelper } from '@/components/Core/Helpers';

export default {
  mixins: [QueryHelper],
  props: {
    id: { type: String, required: true }
  },
  query(props) {
    return {
      query: getPayableInvoiceDocument,
      variables: {
        id: props.id
      }
    };
  }
};
</script>
