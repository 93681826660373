<script>
import { QueryMixin } from '@/components/Core/Helpers';
import { getSupplierInsertionOrderDocument } from '@/graphql/supplier/getSupplierInsertionOrderDocument.graphql';
import { urlParams } from '@/utils/helpers';

export default {
  mixins: [QueryMixin],
  props: {
    id: { type: String, required: true },
    supplierId: { type: String, default: null }
  },
  query(props) {
    return {
      query: getSupplierInsertionOrderDocument,
      variables: {
        supplier_id: props.supplierId || this.$store.getters['auth/supplierId'],
        id: props.id,
        show_unapproved_ads: !!urlParams('show_unapproved_ads')
      }
    };
  }
};
</script>
