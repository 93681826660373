<template>
  <div class="page-payable-invoice-document">
    <payable-invoice-document-query
      :id="$route.params.id"
      :loading.sync="isLoadingInvoice"
      @data="onLoadedInvoice"
    />

    <div class="invoice-page-header relative text-2xl">
      <transition name="fade">
        <div
          v-if="invoice"
          class="invoice-header-content flex items-center justify-center"
        >
          <div class="invoice-header-info font-bold flex-grow"></div>
        </div>
      </transition>
    </div>

    <el-alert
      v-if="!invoice && !isLoadingInvoice"
      class="text-center text-lg text-bold my-4 mx-auto w-9/10"
      title="Unable to find the requested Invoice"
      type="error"
    />

    <div class="invoice-doc-box">
      <div class="invoice-doc-wrap flex justify-center my-5">
        <div class="flex-none">
          <invoice-document
            class="m-auto"
            shadow
            :invoice="invoice"
            :disabled="isExporting"
            :exporting="isExporting"
          />
        </div>

        <div class="ml-5">
          <invoice-document-sidebar
            class="invoice-document-sidebar"
            :invoice="invoice"
            customer-view
            :is-exporting.sync="isExporting"
            @update:isExporting="isExporting = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InvoiceDocument from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocument';

import InvoiceDocumentSidebar from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocumentSidebar';
import PayableInvoiceDocumentQuery from '@/components/Queries/Accounting/PayableInvoiceDocumentQuery';

export default {
  components: {
    PayableInvoiceDocumentQuery,
    InvoiceDocumentSidebar,
    InvoiceDocument
  },
  data() {
    return {
      invoice: null,
      isLoadingInvoice: false,
      isExporting: false
    };
  },
  methods: {
    onLoadedInvoice(data) {
      this.invoice = data;
    }
  }
};
</script>

<style lang="scss">
.invoice-doc-box {
  overflow: auto;
  height: 100vh;
}

@media print {
  .invoice-doc-box {
    overflow: visible;
  }

  .invoice-page-header,
  .invoice-document-sidebar {
    display: none;
  }

  .invoice-doc-wrap {
    display: block;
    padding: 0;
    background: white;
  }

  .invoice-document {
    box-shadow: none;
  }
}
</style>
