<template>
  <div class="transaction-history-list">
    <div
      v-for="transaction in sortedTransactions"
      :key="'transaction-' + transaction.id"
      class="flex tx-record"
    >
      <div class="tx-item flex-shrink-0 w-28 ">
        <div class="tx-date">{{ transaction.date | date }}</div>
        <div class="tx-status">
          <status-icon
            :icons="TransactionStatusInfo"
            :status="transaction.status"
            inline
          />
        </div>
        <div class="tx-id text-md">ID: {{ transaction.id }}</div>
      </div>
      <div class="tx-item tx-amount w-20 flex-shrink-0">
        <div>{{ transaction.amount | currency }}</div>
        <div
          v-if="+transaction.refunded_amount"
          class="text-dark-silver text-sm mt-2"
        >
          <div>Refunded</div>
          <div>{{ transaction.refunded_amount | currency }}</div>
        </div>
      </div>
      <div class="tx-item tx-info flex-grow">
        <div v-if="transaction.paymentMethod" class="font-bold">
          {{ transaction.paymentMethod.name }}
        </div>
        <div>{{ transaction.description }}</div>
      </div>
      <div v-if="isVoidable" class="tx-item flex-shink-0 w-5">
        <template v-if="transaction.status !== TransactionStatus.VOID">
          <template v-if="!isVoiding[transaction.id]">
            <el-tooltip
              v-if="!transaction.provider"
              :key="'tx-voidable-' + transaction.id"
              content="Void Payment"
              effect="light"
            >
              <a
                class="block text-xl text-red"
                @click="
                  $set(isVoiding, transaction.id, true) &&
                    $emit('void', transaction)
                "
              >
                <icon :icon="voidIcon" />
              </a>
            </el-tooltip>
            <el-tooltip
              v-else
              effect="light"
              content="You cannot Void Credit Card Payments. Please contact us for help with this."
            >
              <a
                class="text-silver hover:text-silver cursor-not-allowed block text-xl"
              >
                <icon :icon="voidIcon" />
              </a>
            </el-tooltip>
          </template>

          <div v-else :key="'loading-tx-' + transaction.id">
            <icon :icon="loadingIcon" spin />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { TransactionStatus, TransactionStatusInfo } from '@/constants';

import StatusIcon from '@/components/Core/Statuses/StatusIcon';

import { arrowsCw as loadingIcon, disable as voidIcon } from '@/vendor/icons';

export default {
  components: { StatusIcon },
  props: {
    isLoading: Boolean,
    isVoidable: Boolean,
    transactions: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      TransactionStatusInfo,
      TransactionStatus,
      isVoiding: {},

      // Icons
      loadingIcon,
      voidIcon
    };
  },

  computed: {
    sortedTransactions() {
      return [...this.transactions].sort((a, b) => (a.date > b.date ? -1 : 1));
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.tx-record {
  padding: 0.5em;

  &:nth-child(even) {
    background: $color-light-gray;

    .hover\:text-silver,
    .text-silver {
      color: white;
    }
  }

  .tx-item {
    & + .tx-item {
      margin-left: 1em;
    }
  }
}
</style>
