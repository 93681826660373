<template>
  <div id="page-print-invoice-document">
    <payable-invoice-document-query :id="$route.params.id">
      <template v-slot="{ isLoading, data }">
        <invoice-document :invoice="data" />
      </template>
    </payable-invoice-document-query>
  </div>
</template>

<script>
import InvoiceDocument from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocument';
import PayableInvoiceDocumentQuery from '@/components/Queries/Accounting/PayableInvoiceDocumentQuery';

export default {
  components: {
    PayableInvoiceDocumentQuery,
    InvoiceDocument
  },
  data() {
    return {};
  }
};
</script>
