<template>
  <transition name="fadeLeft">
    <div
      v-if="invoice"
      class="invoice-document-sidebar relative p-5"
      :class="{ 'is-collapsed': isCollapsed }"
    >
      <div class="sidebar-slider">
        <div
          class="sidebar-toggle-menu text-center"
          @click="isCollapsed = !isCollapsed"
        >
          <el-tooltip effect="light" content="Settings and Actions">
            <div class="toggle-icon text-blue text-3xl">
              <icon :icon="toggleIcon" />
            </div>
          </el-tooltip>
          <div class="sidebar-info-icon">
            <status-icon
              class="mt-1"
              :icons="InvoiceStatusIcons"
              :status="invoice.status"
            />
          </div>
        </div>

        <div class="sidebar-items relative">
          <transition-group
            tag="div"
            name="fadeDown"
            class="animate-position sidebar-animated-group"
          >
            <div :key="'sidebar-summary'" class="sidebar-item sidebar-summary">
              <invoice-document-summary :invoice="invoice" />
            </div>

            <div
              v-if="showPaymentSection"
              :key="'sidebar-payment'"
              class="sidebar-item sidebar-payment"
            >
              <supplier-invoice-document-mutation>
                <template v-slot="{ isSaving, invoiceAllowCreditCardPayment }">
                  <div class="flex">
                    <h3 class="flex-grow">Pay With Credit Card</h3>
                    <el-tooltip
                      v-if="isCreditCardAllowed"
                      effect="light"
                      content="Disable Credit Card payments for your Ad Shop"
                      class="leading-none"
                    >
                      <div class="text-right">
                        <a
                          v-if="!isSaving"
                          class="text-right"
                          @click="invoiceAllowCreditCardPayment(invoice, false)"
                        >
                          Disable
                        </a>
                        <div v-else>
                          Disabling...
                          <icon :icon="savingIcon" spin />
                        </div>
                      </div>
                    </el-tooltip>
                  </div>

                  <invoice-credit-card-payment
                    v-if="isCreditCardAllowed"
                    :invoice="invoice"
                  />
                  <div v-else class="text-dark-silver mt-5">
                    Credit Card Payments are disabled for your Ad Shop. Enabling
                    payments will allow all customers to pay via Credit Card.
                    <div class="mt-5">
                      <el-button
                        v-if="!isSaving"
                        class="button-blue w-full"
                        @click="invoiceAllowCreditCardPayment(invoice, true)"
                      >
                        Enable Credit Card Payments
                      </el-button>
                      <loading-button v-else />
                    </div>
                  </div>
                </template>
              </supplier-invoice-document-mutation>
            </div>

            <div
              v-if="!isVoided"
              :key="'sidebar-payment-history'"
              class="sidebar-item sidebar-payment-history"
            >
              <h3 class="pb-5">Payment History</h3>

              <supplier-billing-mutation
                v-if="invoice.transactions && invoice.transactions.length > 0"
              >
                <template v-slot="{ isSaving, voidInvoicePayment }">
                  <transaction-history-list
                    :transactions="invoice.transactions"
                    :is-voidable="$can('pub_manage_invoices')"
                    :is-loading="isSaving"
                    @void="voidInvoicePayment(invoice, $event)"
                  />
                </template>
              </supplier-billing-mutation>
              <div v-else class="text-dark-silver">
                No payments have been made
              </div>
            </div>
          </transition-group>

          <div class="sidebar-action-menu">
            <transition name="fade" mode="out-in">
              <div v-if="invoice">
                <supplier-invoices-action-menu
                  button
                  hide-action-button
                  :pay-action="!customerView"
                  :share-action="!customerView"
                  :void-action="!customerView"
                  :delete-action="!customerView"
                  export-action
                  print-action
                  class="text-lg ml-3"
                  :invoice="invoice"
                  @delete="$router.push({ name: 'supplier.invoices' })"
                  @update:is-exporting="$emit('update:is-exporting', $event)"
                />
              </div>
              <div v-else class="flex justify-end">
                <loader-box :width="2" :height="2" />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import SupplierInvoicesActionMenu from '@/components/Supplier/Invoices/SupplierInvoicesActionMenu';
import InvoiceDocumentSummary from '@/components/Supplier/Billing/Document/InvoiceDocument/InvoiceDocumentSummary';
import TransactionHistoryList from '@/components/Payment/TransactionHistoryList';

import {
  angleDoubleLeft as toggleIcon,
  arrowsCw as savingIcon
} from '@/vendor/icons';
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import { InvoiceStatusIcons, InvoiceStatusKeys } from '@/constants';
import SupplierBillingMutation from '@/components/Mutations/Supplier/SupplierBillingMutation';
import InvoiceCreditCardPayment from '@/components/Payment/InvoiceCreditCardPayment';
import SupplierInvoiceDocumentMutation from '@/components/Mutations/Supplier/SupplierInvoiceDocumentMutation';

export default {
  components: {
    SupplierInvoiceDocumentMutation,
    InvoiceCreditCardPayment,
    SupplierBillingMutation,
    StatusIcon,
    TransactionHistoryList,
    InvoiceDocumentSummary,
    SupplierInvoicesActionMenu
  },
  props: {
    invoice: {
      type: Object,
      default: null
    },
    customerView: Boolean
  },
  data() {
    return {
      isCollapsed: true,
      InvoiceStatusIcons,

      // Icons
      savingIcon,
      toggleIcon
    };
  },
  computed: {
    showPaymentSection() {
      // We want to show the Payment section only if the Invoice can be paid.
      // Further we will hide it if the user is not a Supplier and Credit Card Payments are not allowed
      return (
        this.canPayWithCreditCard &&
        this.invoice &&
        this.invoice.is_tracked_by_source &&
        ((this.isSupplier && this.$can('pub_manage_invoices')) ||
          this.isCreditCardAllowed)
      );
    },
    isSupplier() {
      return this.$store.getters['auth/isSupplier'];
    },
    isCreditCardAllowed() {
      return this.invoice.settings.allowCreditCardPayment;
    },
    canPayWithCreditCard() {
      return +this.invoice.remaining_amount > 0 && !this.isVoided;
    },
    isVoided() {
      return this.invoice.status === InvoiceStatusKeys.VOID;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '~@/scss/_variables.scss';

$contentWidth: 27em;

.sidebar-action-menu {
  position: absolute;
  top: 0;
  right: 0;
}

.sidebar-items {
  width: $contentWidth;
}

.sidebar-item {
  & + .sidebar-item {
    margin-top: 1em;
    padding-top: 1em;
    border-top: 1px solid $color-gray;
  }
}

@media (min-width: 1391px) {
  .sidebar-toggle-menu {
    display: none;
  }
}

@media (max-width: 1390px) {
  $toggleWidth: 3em;

  .invoice-document-sidebar {
    width: $toggleWidth;
    overflow: visible;
    height: 100%;
    padding: 0;

    .sidebar-slider {
      display: flex;
      position: absolute;
      background: white;
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      top: 0;
      left: -$contentWidth;
      transition: all 0.5s;
      width: 100em;
      height: 100%;
      justify-content: flex-start;
      padding: 2em 0.5em;

      .sidebar-toggle-menu {
        width: $toggleWidth;
        cursor: pointer;

        .toggle-icon {
          padding-bottom: 0.5em;

          svg {
            transition: all 0.5s;
            transform: rotate(180deg);
          }
        }

        .sidebar-info-icon {
          opacity: 0;
          transition: all 0.5s;
          margin-top: 1rem;
        }
      }

      .sidebar-items {
        transition: opacity 0.5s;
      }
    }

    &.is-collapsed {
      .sidebar-slider {
        left: 0;
        box-shadow: none;

        .toggle-icon {
          svg {
            transform: rotate(0);
          }
        }

        .sidebar-info-icon {
          opacity: 1;
        }
      }

      .sidebar-items {
        opacity: 0;
      }
    }
  }
}
</style>
