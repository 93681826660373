<template>
  <div class="invoice-document-summary text-left text-lg relative">
    <transition name="fade" mode="out-in">
      <div
        v-if="invoice"
        :key="'invoice-summary-loaded'"
        class="justify-start flex"
      >
        <status-icon
          class="mt-1"
          :icons="InvoiceStatusIcons"
          :status="invoice.status"
        />

        <div class="ml-5 invoice-summary">
          <div class="invoice-summary-ref font-bold">
            Invoice #{{ invoice.ref }}
            <el-tag v-if="isDeleting" type="danger">Deleting</el-tag>
          </div>
          <div class="invoice-summary-due-date flex text-base">
            <div>Due Date: {{ invoice.due_date | date }}</div>
            <template v-if="+invoice.remaining_amount > 0">
              <div v-if="isOverdue" class="text-red">
                &nbsp;(Overdue {{ daysOverdue }} days)
              </div>
              <div v-else>&nbsp;({{ -1 * daysOverdue }} days)</div>
            </template>
          </div>
          <div class="invoice-summary-amount-due text-base">
            Amount Due: {{ invoice.remaining_amount | currency }}
          </div>
        </div>
      </div>
      <div v-else :key="'invoice-summary-loading'">
        <div class="flex justify-start">
          <div>
            <loader-box :width="2.5" :height="2.5" />
          </div>
          <div class="ml-5">
            <loader-text class="w-48" :lines="3" :thickness="1" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import StatusIcon from '@/components/Core/Statuses/StatusIcon';
import { InvoiceStatusIcons } from '@/constants';

import { momentTz } from '@/utils/date';

export default {
  components: {
    StatusIcon
  },
  props: {
    invoice: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      InvoiceStatusIcons
    };
  },
  computed: {
    isOverdue() {
      return this.daysOverdue > 0;
    },
    isDeleting() {
      return !!this.invoice.delete_started_at;
    },
    daysOverdue() {
      return (
        this.invoice && momentTz().diff(momentTz(this.invoice.due_date), 'days')
      );
    }
  }
};
</script>
